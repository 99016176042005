<template>
  <div>
    <div v-if="content" :style="themeStylesWithBackground">
      <Ticker
        :text="content.ticker_title || content.title"
        class="border-t border-b py-1 z-50 relative"
        :style="themeStylesBar"
        :key="$route.path"
      />
      <ul
        id="project-nav"
        class="flex flex-col md:flex-row space-y-2 md:space-y-0 font-semibold pt-1 pb-3 md:py-3 border-b border-black pl-3 md:px-6 md:space-x-6 z-50 relative leading-none sticky top-0"
        :style="themeStylesBar"
      >
        <li class="hidden md:block">
          <router-link :to="{ name: 'Home' }">{{ backText }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Publication' }">{{
            content.home_title || content.title || "Overview"
          }}</router-link>
        </li>
        <li v-if="content.blueprint === 'Project' && hasArtworks">
          <router-link
            :to="{ name: 'Artworks', params: { floor: 'all' } }"
            :class="{ active: $route.name == 'Artworks' }"
            >{{ artistsTitle }}</router-link
          >
        </li>
        <li v-if="content.subpages">
          <ul class="flex space-x-3 md:space-x-6">
            <li v-for="subpage in content.subpages" :key="subpage.id">
              <router-link
                :to="{ name: 'Content', params: { contentSlug: subpage.slug } }"
                >{{ subpage.display_title || subpage.title }}</router-link
              >
            </li>
          </ul>
        </li>
        <!-- <li>Events</li> -->
      </ul>
      <span id="bounce-top" />
      <img
        v-if="$route.name === 'Publication' && content.banner"
        class="hidden md:block w-full z-20 relative"
        :src="content.banner.url"
      />
      <img
        v-if="$route.name === 'Publication' && content.banner_mobile"
        class="md:hidden w-full"
        :src="content.banner_mobile.url"
      />
      <div
        v-if="$route.name === 'Publication'"
        class="m-3 md:m-6 content z-20 relative"
      >
        <div class="flex flex-col md:flex-row md:items-end">
          <h1
            class="text-xl md:text-huge font-bold md:leading-none"
            v-html="content.display_title"
          ></h1>
        </div>
        <div
          v-if="content.credits && content.credits.length"
          class="text-xl mt-6 max-w-6xl"
        >
          <div v-for="(credit, idx) in content.credits" :key="idx">
            {{ credit.label }}
            <span v-if="credit.items.length">
              <span v-for="item in credit.items" :key="item.id">
                <span class="flex items-center">
                  <span class="font-bold">{{ item.title }}</span>
                  <span
                    v-if="credit.bio"
                    @click="toggleBio(item)"
                    class="ml-3 flex items-center justify-center px-4 border rounded-full leading-none tracking-widest cursor-pointer"
                    >•••</span
                  >
                </span>
                <div
                  v-if="currentBio === item.id"
                  v-html="item.content"
                  class="mt-3 mb-6"
                ></div>
              </span>
            </span>
          </div>
        </div>
        <div
          v-if="content.abstract"
          v-html="content.abstract"
          class="text-xl mt-6 max-w-6xl"
        ></div>
        <div
          v-html="content.content"
          class="font-semibold mt-8 max-w-4xl p-spacing pb-24 md:text-lg xl:text-xl"
          @click="handleClick"
        ></div>
      </div>
      <img
        v-if="$route.name === 'Publication' && content.banner_footer"
        class="w-full"
        :src="content.banner_footer.bg"
      />
      <router-view
        :content="content"
        class="relative z-20"
        :themeStyles="themeStyles"
        :themeStylesWithBackground="themeStylesWithBackground"
        v-on="$listeners"
      />
      <div v-if="content.bounce_images_landing && !hideBounce">
        <Bounce
          v-for="image in content.bounce_images_landing"
          :key="image.filename"
          :image="image"
          :shadow="false"
          :rotate="true"
        />
      </div>
      <div
        v-if="content.bounce_images_landing"
        class="select-none fixed bottom-0 right-0 mb-8 mr-12 uppercase border px-4 py-2 rounded-full cursor-pointer z-20 hidden md:block"
        @click="hideBounce = !hideBounce"
      >
        <span v-if="hideBounce">Show</span>
        <span v-else>Hide</span>
        &nbsp;<span>Images</span>
      </div>
    </div>
  </div>
</template>

<script>
import Plyr from "plyr"
import KeenSlider from "keen-slider"
import Ticker from "@/components/Ticker"
import Bounce from "@/components/Bounce"

export default {
  name: "Publication",
  props: ["themeStyles", "themeStylesWithBackground", "themeStylesBar"],
  components: {
    Ticker,
    Bounce,
  },
  data() {
    return {
      contentData: null,
      currentBio: null,
      sliders: [],
      currentSlider: null,
      players: [],
      hideBounce: false,
    }
  },
  mounted() {
    this.fetchContent()
  },
  methods: {
    toggleBio(item) {
      if (this.currentBio === item.id) {
        this.currentBio = null
      } else {
        this.currentBio = item.id
      }
    },
    fetchContent() {
      try {
        fetch(
          `${process.env.VUE_APP_API_URL}/projects/${this.$route.params.slug}.json`,
          {
            mode: "cors",
          }
        ).then((data) => {
          data.json().then((json) => (this.contentData = json))
        })
      } catch (err) {
        return err
      }
    },
    initVideos() {
      this.$nextTick(() => {
        document.getElementsByClassName("plyr__video-embed").forEach((x) => {
          this.players.push(
            new Plyr(x, {
              fullscreen: {
                enabled: true,
                fallback: true,
                iosNative: true,
                container: null,
              },
            })
          )
        })
      })
    },
    initSlideshows() {
      this.sliders = []
      this.$nextTick(() => {
        document.getElementsByClassName("keen-slider").forEach((x) => {
          let slider = new KeenSlider(
            x,
            {
              loop: true,
              slides: {
                spacing: 15,
              },
              created: () => {},
            },
            [
              // add plugins here
            ]
          )
          this.sliders[x.getAttribute("data-slider")] = slider
        })
      })
    },
    handleClick(e) {
      if (e.target.matches(".slider-next")) {
        this.sliders[e.target.getAttribute("data-slider")].next()
      }
      if (e.target.matches(".slider-prev")) {
        this.sliders[e.target.getAttribute("data-slider")].prev()
      }
      if (e.target.matches(".slider-fullscreen")) {
        this.currentSlider = e.target.getAttribute("data-slider")
        document
          .getElementById(`slider-${e.target.getAttribute("data-slider")}`)
          .classList.add("fullscreen")

        this.$nextTick(() => {
          window.addEventListener("keyup", this.handleKeys)
          this.sliders[this.currentSlider].update()
        })
      }
      if (e.target.matches(".slider-close")) {
        this.closeSlider()
      }
    },
    closeSlider() {
      document
        .getElementById(`slider-${this.currentSlider}`)
        .classList.remove("fullscreen")
      this.$nextTick(() => {
        window.removeEventListener("keyup", this.handleKeys)
        this.sliders[this.currentSlider].update()
        this.currentSlider = null
      })
    },
    handleKeys(e) {
      if (this.currentSlider) {
        if (e.keyCode === 39) {
          this.sliders[this.currentSlider].next()
        } else if (e.keyCode === 37) {
          this.sliders[this.currentSlider].prev()
        } else if (e.keyCode === 27) {
          this.closeSlider()
        }
      }
    },
    initExpanders() {
      this.$nextTick(() => {
        document.querySelectorAll(".expander").forEach((x) => {
          console.log(
            document.getElementById(`expand-item-${x.getAttribute("data-key")}`)
          )

          x.addEventListener("click", () => {
            x.classList.toggle("showing")
            document
              .getElementById(`expand-item-${x.getAttribute("data-key")}`)
              .classList.toggle("hidden")
          })
        })
      })
    },
  },
  computed: {
    content() {
      if (this.contentData) {
        return this.contentData[this.$route.params.lang]
      } else {
        return false
      }
    },
    hasArtworks() {
      return (
        this.content.f0.length &&
        this.content.f1.length &&
        this.content.f_1.length
      )
    },
    backText() {
      if (this.$route.params.lang === "cn") {
        return "返回"
      } else if (this.$route.params.lang === "de") {
        return "Zurück"
      } else {
        return "Back"
      }
    },
    artistsTitle() {
      if (this.$route.params.lang === "cn") {
        return "艺术家及作品简介"
      } else if (this.$route.params.lang === "de") {
        return "Künstler und Kunstwerke"
      } else {
        return "Artists & Artworks"
      }
    },
  },
  watch: {
    content() {
      if (this.content.bg_image && this.content.bg_image !== null) {
        this.$emit("theme", {
          type: "image",
          image: `url('${this.content.bg_image.url}')`,
          bg: this.content.color_bg,
          text: this.content.color_text,
          repeat: this.content.bg_repeat,
          override: this.content.override_text,
        })
      } else if (
        this.content.color_text !== null &&
        this.content.color_bg !== null
      ) {
        this.$emit("theme", {
          type: "color",
          text: this.content.color_text,
          bg: this.content.color_bg,
          override: this.content.override_text,
        })
      }
      this.initVideos()
      this.initSlideshows()
      this.initExpanders()
    },
  },
}
</script>

<style lang="css" scoped>
#banner {
  @apply w-full;
}

.router-link-exact-active {
  @apply italic underline;
}
.cover {
  height: 80vh;

  >>> img {
    @apply w-full h-full object-contain;
  }
}

.with-shadow >>> img {
  @apply shadow-xl;
}

.side {
  >>> img {
    @apply w-full;
  }
}

>>> figure + figure {
  margin-top: 2em;
}
>>> figure.video {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;

  iframe {
    @apply absolute top-0 left-0 w-full h-full border-0;
  }
}

>>> a.button {
  @apply py-2 px-8 my-3 rounded-full border text-xl text-center;
  display: inline-block;

  &:hover {
    text-decoration: none;
  }
}
</style>
