<template>
  <div class="marquee flex">
    <marquee-text :duration="tickerDuration">
      <span
        v-for="n in 10"
        :key="n"
        class="
          marquee-item
          uppercase
          flex-grow
          whitespace-nowrap
          font-semibold
          tracking-wide
        "
        v-html="tickerText"
      ></span>
    </marquee-text>
  </div>
</template>

<script>
import MarqueeText from 'vue-marquee-text-component'

export default {
  name: 'Ticker',

  components: { MarqueeText },

  props: {
    text: {
      type: String,
      default: '',
    },
    duration: {
      type: Number,
      default: 2000,
    },
  },
  computed: {
    tickerText() {
      return this.$props?.text
    },

    tickerDuration() {
      return this.$props?.duration || 5000 / this.$props.text.length
    },
  },
}
</script>

<style lang="css" scoped>
.marquee {
  overflow: hidden;
  --offset: 20vw;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));
}

.marquee-item {
  margin-right: 7px;
}

@keyframes marquee {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}
</style>
